.App {
  text-align: center;
}
/* Font Google - strong reaload time */
@import url(http://fonts.googleapis.com/css?family=Source+Sans+Pro:400,200,300,700,900&subset=latin,latin-ext);

html, body{
  font-family: 'Source Sans Pro', sans-serif;
  font-size:15px;
  -webkit-font-smoothing: subpixel-antialiased!important;
  color:black;
  font-weight:400;
  height:100%;
}

a{
  text-decoration:none;
  outline:none;
}

h1{
  font-size:50px;
  color:white;
  font-weight:bold;
  /* text-transform:uppercase; */
  line-height:1.2;
}

p{
  font-size:19px;
  color:white;
  margin-top:50px;
}


h1 span{
  color:#984b43;
}


::selection {
  background: #fff;
  color:#984b43;
}

::-moz-selection {
  background: #fff;
  color:#984b43;
}


.container{
  position:absolute;
  width:100%;
  height:100%;
}






/* Start page */

.start-page{
  position:relative;
  width:100%;
  height:580px;
  background:url('./img/background/start-page.jpg') 0px 0px fixed;
}

.start-page .opacity{
  position:absolute;
  width:100%;
  height:580px;
  background:rgba(0,0,0,0.7);
}


.start-page .content{
  position:relative;
  width:900px;
  margin:0 auto;
  height:580px;
}

.start-page .content .text{
  position:absolute;
  text-align:center;
  margin:auto;
  top:0; 
  left:0; 
  bottom:0; 
  right:0;
  width:500px;
  height:320px;
}

.start-page .content .text{
  position:absolute;
  text-align:center;
  margin:auto;
  top:0; 
  left:0; 
  bottom:0; 
  right:0;
  width:500px;
  height:320px;
}

.start-page .content .text .read-more{
  width:183px;
  height:47px;
  margin:50px auto;
  line-height:47px;
  border:1px solid white;
  text-transform:uppercase;
  color:white;
  cursor:pointer;
  font-size:19px;
  background:none;
  -webkit-transition: all 0.2s ease-in;
	-moz-transition: all 0.2s ease-in;
	-ms-transition: all 0.2s ease-in;
	-o-transition: all 0.2s ease-in;
	transition: all 0.2s ease-in;
}

.start-page .content .text .read-more:hover{
  color:black;
  background:white;
}

.start-page .content .arrow-down{
  position: absolute;
  bottom: 110px;
  left: 50%;
  margin-left: -10px;
  width: 21px;
  height: 29px;
  background: url(./img/arrow-down.png) no-repeat center center;
  display: block;
  -webkit-animation: bounce-fade 1.2s infinite; /* Safari 4+ */
  -moz-animation:    bounce-fade 1.2s infinite; /* Fx 5+ */
  -o-animation:      bounce-fade 1.2s infinite; /* Opera 12+ */
   animation:        bounce-fade 1.2s infinite; /* IE 10+ */
}


@-webkit-keyframes bounce-fade {
    0%   { opacity: 0; bottom: 70px; }
    100% { opacity: 1; bottom: 35px; }
}
@-moz-keyframes bounce-fade {
    0%   { opacity: 0; bottom: 70px; }
    100% { opacity: 1; bottom: 35px; }
}
@-o-keyframes bounce-fade {
    0%   { opacity: 0; bottom:70px; }
    100% { opacity: 1; bottom: 35px; }
}
@keyframes bounce-fade {
    0%   { opacity: 0; bottom: 70px; }
    100% { opacity: 1; bottom: 35px; }
}


/* Menu mobile */

.menu-media{
  position:relative;
  width:100%;
  height:90px;
  background:#233237;
  z-index:1000;
  display:none;
}

.menu-media .menu-content{
  width:280px;
  position:relative;
  margin:0 auto;
}

.menu-media .menu-content .logo {
   color:white;
   font-weight:700;
   font-size:30px;
   width:200px;
   line-height:90px;
   float:left;
   list-style: none;
}

.menu-media .menu-content .icon{

  width:41px;
  height:23px;
  float:right;
  line-height:100px;

}


/* Menu */

.menu{
  position:relative;
  width:100%;
  height:90px;
  background:#233237;
  z-index:1000;
}

.menu-content{
  width:450px;
  position:relative;
  margin:0 auto;
}

.menu-content .logo {
   color:white;
   background:#233237;
   font-weight:700;
   font-size:30px;
   width:300px;
   line-height:90px;
   float:left;
   list-style: none;
 }


.menu-click{
  display:none;
  padding-inline-start: 0;
  margin-block-start: 0;
}


.menu-click li {
  position:relative;
  width:100%;
  height:70px;
  color:white;
  font-size:29px;
  text-align:center;
  line-height:70px;
  background:#984b43;
  z-index:1000;
  border-bottom:1px solid #1a7966;
  -webkit-transition: all 0.2s ease-in;
	-moz-transition: all 0.2s ease-in;
	-ms-transition: all 0.2s ease-in;
	-o-transition: all 0.2s ease-in;
	transition: all 0.2s ease-in;
 }

.menu-click li:hover {
  color:#233237;
 }

.menu-content ul{

  width:500px;
  left:50%;
  float:right;
  text-align:right;
  list-style:none;
}

.menu-content li{
   display:inline-block;
   position:relative;
}

.menu-content li a{
  color:#a3b2c0;
  font-size:16px;
  display: block;
  padding: 0 20px 0 20px;
  line-height:90px;
  -webkit-transition: all 0.2s ease-in;
	-moz-transition: all 0.2s ease-in;
	-ms-transition: all 0.2s ease-in;
	-o-transition: all 0.2s ease-in;
	transition: all 0.2s ease-in;
}

.menu-content li.active a{
  background-color:#131a21;
}

.menu-content li a:hover{
  color:white;
}


/* About us */

.about-us{
  position:relative;
  width:900px;
  margin:0 auto;
  margin-bottom:10px;

}

.about-us h1{
  font-size:50px;
  color:black;
  text-align:center;
  margin-top:50px;
  font-weight:bold;
  text-transform:uppercase;
  line-height:1.2;

}

.about-us hr{
  color: black;
  margin-top:30px;
  background-color: black;
  height: 1px;
  width:340px;
  border:0;

}

.about-us p.title{
  color: #919191;
  margin-top:40px;
  width:100%;
  font-size:15px;
  text-align:center;
}

.about-us h2{
  text-align:center;
  margin-top:20px;
  font-size:29px;
  color:black;
  line-height:1.2;
  width:100%;
  position:relative;
  font-weight:bold;
}

.about-us p{
  text-align:center;
  margin-top:20px;
  font-size:15px;
  width:100%;
  color:#919191;
  line-height:1.4;
  position:relative;
  font-weight:400;
}

.about-us .column-one{
  margin-top:40px;
  width:300px;
  float:left;
  margin-bottom:70px;
  height:350px;
}

.about-us .column-one .circle-one{
  height: 174px;
  position:relative;
  margin:0 auto;
  border:8px solid #ebebeb;
  background: url(./img/icons/xcode.png) #f3f3f3 no-repeat center center;
  -moz-border-radius:174px;
  -webkit-border-radius:174px;
  border-radius: 174px;
  width: 174px;
  /* cursor:pointer; */
    -webkit-transition: all 0.2s ease-in;
	-moz-transition: all 0.2s ease-in;
	-ms-transition: all 0.2s ease-in;
	-o-transition: all 0.2s ease-in;
	transition: all 0.2s ease-in;
}

/* .about-us .column-one .circle-one:hover{
  background: url(./img/icons/xcode.png) #984b43 no-repeat center center;

} */

.about-us .column-two{
  margin-top:40px;
  width:300px;
  float:left;
  margin-bottom:70px;
  height:350px;
}

.about-us .column-two .circle-two{
  height: 174px;
  position:relative;
  margin:0 auto;
  border:8px solid #ebebeb;
  background: url(./img/icons/unity.png) #f3f3f3 no-repeat center center;
  -moz-border-radius:174px;
  -webkit-border-radius:174px;
  border-radius: 174px;
  width: 174px;
    /* cursor:pointer; */
    -webkit-transition: all 0.2s ease-in;
	-moz-transition: all 0.2s ease-in;
	-ms-transition: all 0.2s ease-in;
	-o-transition: all 0.2s ease-in;
	transition: all 0.2s ease-in;
}

/* .about-us .column-two .circle-two:hover{
  background: url(./img/icons/unity.png) #984b43 no-repeat center center;
} */


.about-us .column-three{ 
  margin-top:40px;
  width:300px;
  float:left;
  margin-bottom:70px;
  height:350px;
}

.about-us .column-three .circle-three{ 
  height: 174px;
  position:relative;
  margin:0 auto;
  border:8px solid #ebebeb;
  background: url(./img/icons/react.png) #f3f3f3 no-repeat center center;
  -moz-border-radius:174px;
  -webkit-border-radius:174px;
  border-radius: 174px;
  width: 174px;
  /* cursor:pointer; */
  -webkit-transition: all 0.2s ease-in;
	-moz-transition: all 0.2s ease-in;
	-ms-transition: all 0.2s ease-in;
	-o-transition: all 0.2s ease-in;
	transition: all 0.2s ease-in;
}

/* .about-us .column-three .circle-three:hover{ 
  background: url(./img/icons/react.png) #984b43 no-repeat center center;
} */



/* Portfolio */


.portfolio{
  position:relative;
  width:100%;
  background:#233237;

}

.portfolio .portfolio-margin{
  position:relative;
  width:950px;
  overflow:hidden;
  margin:0 auto;
}

.portfolio .portfolio-margin .read-more{
  width:183px;
  height:47px;
  position:relative;
  margin:50px auto;
  line-height:47px;
  border:1px solid white;
  text-transform:uppercase;
  text-align:center;
  color:white;
  cursor:pointer;
  font-size:19px;
  background:none;
  -webkit-transition: all 0.2s ease-in;
	-moz-transition: all 0.2s ease-in;
	-ms-transition: all 0.2s ease-in;
	-o-transition: all 0.2s ease-in;
	transition: all 0.2s ease-in;
}

.portfolio .portfolio-margin .read-more:hover{
  color:black;
  background:white;
}

.portfolio .portfolio-margin h1{
  font-size:50px;
  color:white;
  padding-top:50px;
  text-align:center;
  font-weight:bold;
  text-transform:uppercase;
  line-height:1.2;

}

.portfolio .portfolio-margin hr{
  color: black;
  margin-top:30px;
  background-color: white;
  height: 1px;
  width:340px;
  border:0;
}

/* Portfolio grid */

.grid{
  margin-top:70px;
  width:100%;
  position:relative;
  margin-bottom:70px;
  overflow:hidden;
  padding-inline-start: 0px;
}

.grid li{
  width:300px;
  height:225px;
  float: left;
  position: relative;
  overflow: auto;
}

.grid img{
  width: 100%;
  height: 225px;
  float: left;
  position: relative;
}

.grid .text {
  position: absolute;
  width: 300px;
  height: 225px;
  background: #984b43;
  z-index: 2;
  opacity:0;
  float: left;
  top: 0;
}

.grid p {
  font-size: 29px;
  text-align:center;
  margin-top:80px;
  font-weight: bold;
  color: #FFF;
}

.grid p.description {
  font-size: 15px;
  width:300px;
  margin-top:20px;
  text-align:center;
  font-weight: 400;
  color: #FFF;
}

.clear { 
    clear:both; 
}

/* Contact */

.contact{
  position:relative;
  width:455px;
  margin: 0 auto;  
  
}

.contact h1{
  font-size:50px;
  color:black;
  padding-top:50px;
  text-align:center;
  font-weight:bold;
  text-transform:uppercase;
  line-height:1.2;

}

.contact hr{
  margin-top:30px;
  background-color: black;
  height: 1px;
  width:340px;
  border:0;
}


.contact .content{
  position:relative;
  width:455px;
  overflow:hidden;
  margin: 60px 0 65px 0;  
  
}

.contact .content .form{
  width:455px;
  overflow:hidden;
  height:auto;
  float:left;
 
}

.contact .content .contact-text{
  width:405px;
  overflow:hidden;
  height:auto;
  float:left;
  padding-left:30px;
  color:black;
  font-size:17px;
  line-height:1.7;
}

strong{
  font-weight:700;

}

input{

  width:420px;
  height:40px;
  background:#233237;
  border:0;
  color:white;
  font-weight:bold;
  line-height:40px;
  padding-left:35px;
  -moz-border-radius:4px;
  -webkit-border-radius:4px;
  border-radius: 4px;
  font-family: 'Source Sans Pro', sans-serif;
  font-size:17px;
  margin-bottom:10px;
}

textarea{

  width:420px;
  height:283px;
  background:#233237;
  border:0;
  color:white;
  font-weight:bold;
  padding:15px 0 0 35px;
  -moz-border-radius:4px;
  -webkit-border-radius:4px;
  border-radius: 4px;
  font-family: 'Source Sans Pro', sans-serif;
  font-size:17px;
  margin-bottom:10px;
}

.contact .content .form .button{
  width:142px;
  height:34px;
  float:right;
  margin:0;
  background:#233237;
  border:0;
  -moz-border-radius:4px;
  -webkit-border-radius:4px;
  border-radius: 4px;
    -webkit-transition: all 0.2s ease-in;
	-moz-transition: all 0.2s ease-in;
	-ms-transition: all 0.2s ease-in;
	-o-transition: all 0.2s ease-in;
	transition: all 0.2s ease-in;
}

.contact .content .form a .button{
  text-align:center;
  color:white;
  font-weight:bold;
  line-height:34px;
  font-size:17px;
}

.contact .content .form .button:hover{
  color:white;
  background:#984b43;
}

/* Footer */


.footer{
  position:relative;
  width:100%;
  height:90px;
  background:#233237;

}

.footer .margin{
  position:relative;
  width:450px;
  margin:0 auto;
  height:90px;
}

.footer .margin .menu-footer{
  width:450px;
  margin:0 auto;
  float:left;
}


.footer .margin .menu-footer a{
  line-height:90px;
  padding-right:25px;
  color:#5f666e;
  -webkit-transition: all 0.2s ease-in;
	-moz-transition: all 0.2s ease-in;
	-ms-transition: all 0.2s ease-in;
	-o-transition: all 0.2s ease-in;
	transition: all 0.2s ease-in;
}

.footer .margin .menu-footer a:hover{
  color:white;
}


.footer .margin .copyright{
  width:420px;
  margin:0 auto;
  text-align:right;
  float:left;
  line-height:90px;
  padding-right:25px;
  color:#5f666e;
}

